import React from 'react';
import './WhyVSCode.css'; 

function WhyVSCode() {
  return (
    <div className="why-vscode-container"> 
      <h1>Why do Visual Studio Code?<span className='cursor'>|</span></h1>
      <p>
        <br />
A brief explanation of why I chose to make my portfolio resemble Visual Studio Code.
        <br/>
        <hr />
        <br />
        <p>
          <h2>Familiarity</h2>
          <br />
          <p>Visual Studio Code is used by many developers. With it being a popular editor, I wanted to make my portfolio resemble it. This way, when a developer visits my portfolio, they will feel right at home. </p>
        <br/>
        <hr />
        <br />
          <h2>What was your portfolio written in?</h2>
          <br />
          <p>My portfolio was entirely built with Javascript, React, & CSS.</p>
          <br/>
        <hr />
        <br />
          <h2>What was the most challenging part of building your portfolio?</h2>
          <br />
          <p>The most challenging part of building my portfolio was the styling. I wanted to make my portfolio resemble Visual Studio Code as much as possible. I had to use a lot of CSS to get the desired look.
            However, I utilized my resources and was able to get the desired look.
            </p>
            <br/>
        <hr />
        <br />
          <h2>What was the most rewarding part of building your portfolio?</h2>
          <br />
          <p>The most rewarding part of building my portfolio was seeing the final product. 
            I was able to create a portfolio that I am proud of, 
            show to potential employers, 
            show to my friends and family, and show to the world
            </p>
            <br/>
        <hr />
        <br />
          <h2>What do you love about Vscode?</h2>
          <br />
          <p>There are many things that I love about Visual Studio Code.
            I love the fact that it is free, open source, has a large community of developers, a large library of extensions that can be used to enhance the editor, and I love the fact that it is a lightweight editor.
            </p>
            <br/>
        <hr />
        <br />



        </p>
      </p>
      

    </div>
  );





}

export default WhyVSCode;
