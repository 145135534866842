import React from 'react';

function OutputFlixMoviesDoc() {
  return (
    <div>
      <p>Flix Movies was an application built during my time at Codepath. I had the opportunity to take Codepath's iOS University course, and learned so much!</p>
    </div>
  );
}

export default OutputFlixMoviesDoc;
