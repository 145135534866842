import React from 'react';

const LandingPage = () => {
  return (
    <>
      <span className="bold">Anthony Narvaez</span>
      <span> is a software engineer.</span>
    </>
  );
};

export default LandingPage;
