import React from 'react';

function OutputTwootiedoc() {
  return (
    <div>
      <p>Twootie was a major milestone during my time at Codepath. Working with cells, and learnng that many apps use them was surreal to learn. It really made me think of apps a whole different way.</p>
    </div>
  );
}

export default OutputTwootiedoc;
