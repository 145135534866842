import { useState, useEffect } from 'react';

const useTypingEffect = (text, typingSpeed = 100) => {
  const [typedText, setTypedText] = useState('');
  useEffect(() => {
    let index = 0;
    const timer = setInterval(() => {
      if (index <= text.length) {
        setTypedText(text.substring(0, index));
        index++;
      } else {
        clearInterval(timer);
      }
    }, typingSpeed);

    return () => {
      clearInterval(timer);
    };
  }, [text, typingSpeed]);

  return typedText;
};

export default useTypingEffect;
