import React from 'react';

function OutputDataLawyermd() {
  return (
    <div>
        <p>Data Lawyer was my first attempt working with AI on a project, specifically GPT 3.5-Turbo. It was a huge learning experience, and I'm excited to incorporate more Artificial Intelligence in future projects. </p>
    </div>
  );
}

export default OutputDataLawyermd;
