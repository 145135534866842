import React from 'react';

function StyleCss() {
  const imageStyle = {
    width: '100%',
    height: 'auto',
    maxWidth: '500px',
  };

  const sectionStyle = {
    marginBottom: '20px',
  };

  return (
    <div className="why-vscode-container"> 
      <h1>Data Lawyer Demo's<span className='cursor'>|</span></h1>
      <div style={sectionStyle}>
        <h2>UI Demo</h2>
        <p>Data Lawyer is a groundbreaking project that aims to demystify privacy policies for everyday users. By leveraging the power of OpenAI's GPT-3 model, Data Lawyer can read, understand, and summarize privacy policies, allowing users to quickly and easily understand how their data will be used and managed.</p>
        <img src="/DataLawyerTextbox.png" alt="ListenUp Demo" className="ListenUp-Demo" style={imageStyle} />
      </div>
      
      <hr />

      <div style={sectionStyle}>
        <h2>Model Reading Privacy Policy Demo</h2>
        <p>One of the key features of Data Lawyer is the ability to process and summarize complex privacy policies. After a user pastes a privacy policy text into the application, Data Lawyer gets to work, parsing the text and extracting the key points. The result is a comprehensive yet understandable summary of how the user's data is managed, highlighting any potential areas of concern. This feature makes it significantly easier for users to protect their privacy and stay informed about their data rights.</p>
        <img src="/DataLawyerPromptDemo.gif" alt="ListenUp List Gif Demo" style={imageStyle} />
      </div>
      
      <hr />

      <div style={sectionStyle}>
        <h2>The Prompt</h2>
        <p>The power of Data Lawyer lies in its ability to intelligently process and understand the text of privacy policies. To achieve this, it was trained with a specific prompt: 'You are a cybersecurity analyst specializing in analyzing privacy policies. Please analyze the following privacy policy and provide a summary in bullet points of how user data is being used. If the input does not resemble a privacy policy, respond with 'Please only give me a privacy policy'.' This prompt ensures that Data Lawyer stays focused on its task and consistently delivers accurate and meaningful summaries.</p>
      </div>
    </div>
  );
}

export default StyleCss;
