import React from 'react';
import './Footer.css';

function Footer({ position }) {
  return (
    <footer className="vscode-footer">
      <div className="footer-left">
        <div className="footer-section">
          <i className="fa fa-code-fork" aria-hidden="true"></i> main*
        </div>
        <div className="footer-section">
          <i className="fa fa-times-circle" aria-hidden="true"></i> 0
        </div>
        <div className="footer-section">0</div>
        <div className="footer-section">
          <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> 0
        </div>
        <div className="footer-section">
        <i class="fa fas fa-user" aria-hidden="true"></i> Created by Anthony Narvaez and his passion to create.
        </div>
      </div>
      <div className="footer-right">
        <div className="footer-section">Ln {position.line}, Col {position.column}</div>
        <div className="footer-section">Spaces: 2</div>
        <div className="footer-section">UTF-8</div>
        <div className="footer-section">{ }</div>
        <div className="footer-section">Javascript</div>
        <div className="footer-section">Layout: U.S.</div>
      </div>
    </footer>
  );
}

export default Footer;
