import React from 'react';

function ListenUpReadme() {
  return (
    <div className="why-vscode-container"> 
      <h1>ListenUp - Music Identifier<span className='cursor'>|</span></h1>
      <p>
        <br />
        <h2>What is ListenUp?</h2>
        <br />
        <p>ListenUp is a music identifier that allows users to identify music playing around them, all while their phone is in their pocket. </p>
        <p> You can download ListenUp <a href="https://apps.apple.com/us/app/listenup-music-identifier/id6444579819">here</a>.</p>
        <br/>
        <hr />
        <br />
        <p>
          <h2>Languages</h2>
          <br />
          <p>ListenUp was built in Swift, utilizing SwiftUi. It is an extensive project that is powered by Shazam.</p>
        <br/>
        <hr />
        <br />
          <h2>Subscription System</h2>
          <br />
          <p>The subscription system is done with the help of <a href="https://www.RevenueCat.com">RevenueCat</a>.  This allows users to subscribe to the app and unlock premium features.
            Premium features include access to the history page, which allows users to view their history of identified songs. 
            </p>
          
          <br/>
        <hr />
        <br />
          <h2>Future of ListenUp</h2>
          <br />
          <p>A feature I'd like to see implemented into ListenUp is the ability to utilize dynamic island and live actions. This would allow users to identify music while their phone is locked. </p>
            <br/>
        <hr />
        <br />
          <h2>What was the most rewarding part of building ListenUp?</h2>
          <br />
          <p>The most rewarding part of building ListenUp was utilizing it in real world scenarios. I was able to identify music playing around me, and subscribers are extremely happy with the app. </p>
            </p>
            <br/>

      </p>
      

    </div>
  );





}

export default ListenUpReadme;
