import React from 'react';

function Parsetagramdemo() {
  // Define styles for elements to maintain uniformity and better control over design
  const imageStyle = {
    width: '300px',
    height: 'auto',
  };

  const sectionStyle = {
    marginBottom: '20px',
  };

  return (
    <div className="why-vscode-container"> 
      <h1>Parsetagram Demo's<span className='cursor'>|</span></h1>

      <div style={sectionStyle}>
        <h2>Authentication Demo</h2>
        <p>I utilize <a href='https://www.back4app.com/'>Back4App</a> to implement a secure and efficient user authentication process, providing a seamless user experience along with robust data management.</p>
        <img src="/Parsetagram_Signin_Demo.gif" alt="Authentication Demo" className="Auth-Demo" style={imageStyle} />
      </div>
      
      <hr />

      <div style={sectionStyle}>
        <h2>Content Feed Demo</h2>
        <p>The content feed algorithm I designed retrieves and displays user images from the database, offering an engaging browsing experience.</p>
        <img src="/Parsetagram_Scroll_Demo.gif" alt="Content Feed Demo" style={imageStyle} />
      </div>
      
      <hr />

      <div style={sectionStyle}>
        <h2>Image Upload Demo</h2>
        <p>The upload feature I developed allows users to add their photos to the platform. Upon submission, the user's metadata, image, and timestamp are systematically stored in a single database row.</p>
        <img src="/Parsetagram_Upload_Demo.gif" alt="Upload Demo" style={imageStyle} />
      </div>
    </div>
  );
}

export default Parsetagramdemo;
