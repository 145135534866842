import React from 'react';

function ListenUpReadme() {
  return (
    <div className="why-vscode-container"> 
      <h1>Flix Movies<span className='cursor'>|</span></h1>
      <p>
        <br />
        <h2>What is Flix Movies?</h2>
        <br />
        <p>Flix Movies is a movie database app that allows users to search for movies, and view details about any movie. Upon opening the application, users are greeted with a list of popular movies that are now playing.

        </p>
        <br/>
        <hr />
        <br />
        <p>
          <h2>Languages</h2>
          <br />
          <p>Flix Movies was built in Swift, utilizing SwiftUi. It utilizes <a href="https://developers.themoviedb.org/3/getting-started/introduction">The Movie Database API</a></p>
        <br/>
        <hr />
        <br />
          <h2>Future of Flix Movies</h2>
          <br />
          <p>A feature I'd like to see implemented into Flix movies is the ability to purchase movie tickets for now playing movies. </p>
            <br/>
        <hr />
        <br />
          <h2>What was the most rewarding part of building Flix Movies?</h2>
          <br />
          <p>The most rewarding part of building Flix Movies is being able to use the application to see movie trailers for upcoming / now playing movies</p>
            </p>
            <br/>

      </p>
      

    </div>
  );





}

export default ListenUpReadme;
