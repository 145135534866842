import React from 'react';

function OutputParsetagramDemo() {
  return (
    <div>
      <p>Parsetagram was made to be a very lightweight Instagram clone. Just the barebones to understand how to authenticate and store user information.</p>
    </div>
  );
}

export default OutputParsetagramDemo;
