import React from 'react';

function OutputDataLawyerDemo() {
  return (
    <div>
      <p>If you're new and working with an API with a paid plan. Make sure you set up constraints on your monthly bill!</p>
    </div>
  );
}

export default OutputDataLawyerDemo;
