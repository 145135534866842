import React from 'react';

function OutputAWOLDocMd() {
  return (
    <div>
        <p>
          I use A.W.O.L. to this very day to wake up all of my devices. It's truly a timeless app, and I was so proud once it was released onto Apple's App store. My favorite part of the app is the QR reader, since it makes the process of adding a new device to the app so much easier.
</p>
    </div>
  );
}

export default OutputAWOLDocMd;
