import React from 'react';
import './Editor.css';
import useTypingEffect from './useTypingEffect';
import WhyVSCode from './fileContents/WhyVSCode';
import CVHtml from './fileContents/CVHtml';
// import PrintableCV from './fileContents/PrintableCV';
import ContactHtml from './fileContents/ContactHtml';
import ContactCss from './fileContents/ContactCss';

import ListenUpReadme from './fileContents/ListenUpReadme';
import ListenUpDemos from './fileContents/ListenUpDemos';

import DataLawyerReadme from './fileContents/DataLawyerReadme';
import DataLawyerDemos from './fileContents/DataLawyerDemos';

import ParsetagramReadme from './fileContents/ParsetagramReadme';
import ParsetagramDemos from './fileContents/ParsetagramDemos';

import TwootieReadme from './fileContents/TwootieReadme';
import TwootieDemos from './fileContents/TwootieDemos';

import FlixMoviesReadme from './fileContents/FlixMoviesReadme';
import FlixMoviesDemos from './fileContents/FlixMoviesDemos';

import LaunchGUIDoc from './fileContents/LaunchGUIDoc';
import LaunchGUIDemos from './fileContents/LaunchGUIDemos';

import TurtleVHareReadme from './fileContents/TurtleVHareReadme';
import TurtleVHareDemos from './fileContents/TurtleVHareDemos';



import AWOLReadme from './fileContents/AWOLReadme';
import AWOLDemos from './fileContents/AWOLDemos';




function Editor({ selectedFile }) {
  const typedText = useTypingEffect('Software Engineer', 50);
  

  const renderContent = () => {
    // Get the width of the viewport
    const screenWidth = window.innerWidth;
  
    // Define styles for large and small screens
    const largeScreenImgStyles = {
      width: '50%',
      height: 'auto',
    };
    const smallScreenImgStyles = {
      width: '100%',
      height: 'auto',
      maxWidth: '300px',  // you can adjust this value
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto'
    };
    const avatarLargeScreenStyles = {
      position: 'absolute',
      borderRadius: '50%', 
      width: '100px', 
      height: '100px',
      left: '20px',
      top: '20px',
    };
    const avatarSmallScreenStyles = {
      borderRadius: '50%', 
      width: '100px', 
      height: '100px',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto'
    };
  
    // Use large screen styles for screens wider than 600px, small screen styles otherwise
  // Use large screen styles for screens wider than 600px, small screen styles otherwise
  const imgStyles = screenWidth > 600 ? largeScreenImgStyles : smallScreenImgStyles;
  const avatarStyles = screenWidth > 600 ? avatarLargeScreenStyles : avatarSmallScreenStyles;

    if (!selectedFile || selectedFile.name === 'index.html') {
      return (
        <div className="landing-page" style={{ position: 'relative' }}>
          <img 
            src="https://avatars.githubusercontent.com/u/73564856?v=4" 
          alt="Anthony Narvaez"
          style={avatarStyles}
          />
        <div style={{ textAlign: 'center' }}>
          <h1>Anthony Narvaez</h1>
          <h2>{typedText}<span className="cursor">|</span></h2>
        </div>
        <p>
          <br /> <br />
          Welcome to my portfolio! Browse through my projects, read my CV, learn about my skills, and get in touch with me.
            <br/>
            <br /> <br />
            <hr />
            <br />
            <p>
              <h2>Navigation</h2>
              <br />
              To navigate through my portfolio, click on the icons that are located in the activity bar on the left side of the screen.
              They will open up the sidebar where you can navigate through the website just like Visual Studio Code.
              <br /> <br />  <br />
              <img src="/NavigationTutorial.gif" alt="Navigation GIF" style={imgStyles} />

              <br /> <br />
              <hr /> <br />
              <h2>The Terminal</h2>
              <br />
              The terminal is located at the bottom of the screen. You may close the terminal by clicking the <strong>'X'</strong> on the top right corner of the terminal.
              <br /> <br />
              To bring the terminal back, at any point you may click the run code button in the navigation bar. This will open the terminal and run the code.
              <br /> <br /> <br />
              <img src="/CompileCodeTutorial.gif" alt="Compile Tutorial GIF" style={imgStyles} />
              <br /> <br />  <br />
              Every file runs different code. If you're curious about the output of certain projects, feel free to hit the <strong>run code</strong> button
            </p>
          </p>
          

        </div>
      );
    }

    switch (selectedFile.name) {
      // case 'index.html':
      //   return <p>This is the content of index.html</p>;
      case 'WhyVSCode.js':
        return <WhyVSCode />;
      case 'Demos_Listen.swift':
        return <ListenUpDemos />;
      case 'cv.md':
        return <CVHtml />;
      case 'PrintableCV.html':
        window.location.href = 'https://docs.google.com/document/d/1RyMAonpmuP28oqx6D-c7lYQ34mCIj4-UZ93lcxR757M/edit?usp=sharing';
        return null
      case 'contact.md':
        return <ContactHtml />;
      case 'contact.css':
        return <ContactCss />;
      case 'ListenUpDoc.md':
        return <ListenUpReadme />;
      case 'AWOLDoc.md':
        return <AWOLReadme />;
      case 'Demos_AWOL.swift':
        return <AWOLDemos />;
      case 'DataLawyer.md':
        return <DataLawyerReadme />;
      case 'Demos_Lawyer.css':
        return <DataLawyerDemos />;
      case 'ParsetagramDoc.md':
        return <ParsetagramReadme />;
      case 'Demos_Parse.swift':
        return <ParsetagramDemos />;
      case 'TwootieDoc.md':
        return <TwootieReadme />;
      case 'Demos_Twootie.swift':
        return <TwootieDemos />;
      case 'FlixMoviesDoc.md':
        return <FlixMoviesReadme />;
      case 'Demos_Flix.swift':
        return <FlixMoviesDemos />;
      case 'LaunchGUIDoc.md':
        return <LaunchGUIDoc />;
      case 'Demos_LaunchGUI.css':
        return <LaunchGUIDemos />;
      case 'TurtleVHare.md':
        return <TurtleVHareReadme />;
      case 'Demos_TvsH.css':
        return <TurtleVHareDemos />;
      
      case 'Linkedin.html':
        window.open('https://www.linkedin.com/in/anthonynarvaez/', '_blank');
        return null
      case 'Github.html':
        window.location.href = 'https://github.com/EazyCreator';
        return null


      default:
        return <p>File not found</p>;
    }
  };

  return (
    <div className="editor">
      <div className="container">{renderContent()}</div>
    </div>
  );
}

export default Editor;
