import React from 'react';

function ListenUpReadme() {
  return (
    <div className="why-vscode-container"> 
      <h1>ListenUp - Music Identifier<span className='cursor'>|</span></h1>

      <h2>What is ListenUp?</h2>
      <p>ListenUp is a music identifier that figures out what music is playing around you, even when your phone is in your pocket. You can download ListenUp <a href="https://apps.apple.com/us/app/listenup-music-identifier/id6444579819" target="_blank" rel="noopener noreferrer">here</a>.</p>

      <hr />

      <h2>Technical Bits</h2>
      <p>ListenUp was built in Swift, using SwiftUI. It's a big project that's powered by Shazam's music recognition tech to nail down what song is playing.</p>

      <hr />

      <h2>Subscription System</h2>
      <p>The subscription system is handled with a bit of help from <a href="https://www.RevenueCat.com" target="_blank" rel="noopener noreferrer">RevenueCat</a>. Subscribers get to unlock premium features, including access to a history page where they can check out all the songs they've identified so far.</p>

      <hr />

      <h2>Future of ListenUp</h2>
      <p>One thing I'd love to add to ListenUp is the ability to use dynamic island and live actions. It'd be really cool if users could identify music even when their phone is locked.</p>

      <hr />

      <h2>What was the best part of building ListenUp?</h2>
      <p>The most rewarding part of building ListenUp? Definitely getting to use it in real life. It's great to be able to identify music playing around me, and it's even better to hear how happy subscribers are with the app.</p>
    </div>
  );
}

export default ListenUpReadme;
