import React from 'react';

function ListenUpReadme() {
  return (
    <div className="why-vscode-container"> 
      <h1>Parsetagram<span className='cursor'>|</span></h1>
      <p>
        <br />
        <h2>What is Parsetagram?</h2>
        <br />
        <p>Parsetagram is a social media app that allows users to post pictures, view other users posts, and comment on posts. It was built in the likeness of Instagram.

        </p>
        <br/>
        <hr />
        <br />
        <p>
          <h2>Languages</h2>
          <br />
          <p>Parsetagram was built in Swift, utilizing UIKit. All data is stored in a SQL database</p>
        <br/>
          
          <br/>
        <hr />
        <br />
          <h2>Future of Parsetagram</h2>
          <br />
          <p>Parsetagram is a completed project, and will no longer be receiving any planned updates. It was built for me develop an understanding of creating a social media application.</p>
            <br/>
        <hr />
        <br />
          <h2>What was the most rewarding part of building Parsetagram?</h2>
          <br />
          <p>The most rewarding part of building Parsetagram was giving friends and family access through Testflight. Once they were given access, they were able to send and comment on each others photos. </p>
            </p>
            <br/>

      </p>
      

    </div>
  );





}

export default ListenUpReadme;
