import React from 'react';

function StyleCss() {
  const imageStyle = {
    width: '300px',
    height: 'auto',
  };


  const marginRightStyle = {
    marginRight: '10px',
  };

  return (
    <div className="why-vscode-container"> 
      <h1>ListenUp - Music Identifier Demo's<span className='cursor'>|</span></h1>
      <p>
        <br />
        <h2>Finding Music Demo</h2>
        <br />
        <p>ListenUp - Music Identifier utilizes Shazam to identify music playing around you.</p>
        <img src="/ListenUpFindDemo.gif" alt="ListenUp Demo" className="ListenUp-Demo" style={imageStyle} />
        <br/>
        <hr />
        <br />
        <p>
          <h2>Date List / Music List Demo</h2>
          <br />
          <p>The music list would only be available to those that are ListenUp+</p>
          <img src="/ListenUpListDemo.gif" alt="ListenUp List Gif Demo" style={{ ...imageStyle, ...marginRightStyle }} />
          <img src='/Listenup_Paywall_Demo.png' alt='ListenUp Paywall Demo' style={imageStyle} />
        </p>
        <br/>
        <hr />
        <br />
        <p style={{ textAlign: 'center' }}> 
          <h2>The icon</h2>
          <br />
          <p>The icon for Listenup was created to resemble music notes and sound waves. </p>
          <img src="/ListenUpIcon.png" alt="ListenUp Icon" className="ListenUp-Icon"/>
        </p>
          
      </p>
    </div>
  );
}

export default StyleCss;
