import React from 'react';

function OutputDemosAWOLSwift() {
  return (
    <div>
      <p>During the test phase of A.W.O.L. I would utilize
        Wireshark to check for packets being sent to my devices in my network.

      </p>
    </div>
  );
}

export default OutputDemosAWOLSwift;
