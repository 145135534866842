import React from 'react';

function OutputTwootieDemo() {
  return (
    <div>
      <p>Twootie is a project built with Twitter in mind, and I learned a lot
        about working with the Twitter API. I plan no further development on
        this project, but it was a great learning experience.
      </p>
    </div>
  );
}

export default OutputTwootieDemo;
