import React from 'react';


function OutputWhyVSCode() {
  return (
    <div>
      <p>
        I have used Visual Studio Code for most of my career. 
        I enjoy how organized it is and how it allows me to customize my workspace.
        Some of my favorite extensions are prettier, 
        live server, 
        Peacock, 
        Bookmarks, 
        and Github Copilot.
</p>
    </div>
  );
}

export default OutputWhyVSCode;
