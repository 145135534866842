import React from 'react';

function OutputContactMd() {
  return (
    <div>
      <p>Send me a message if you want to work on a project, reach out about opportunities, or just say hi. My contact system was built with EmailJS.  </p>
    </div>
  );
}

export default OutputContactMd;
