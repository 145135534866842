import React, { useEffect, useState } from 'react';
import './Console.css';
import LandingPage from './ConsoleContents/LandingPage';
import OutputCVHtml from './ConsoleContents/OutputCVHtml';
import OutputWhyVSCode from './ConsoleContents/OutputWhyVSCode';
import OutputContactMd from './ConsoleContents/OutputContactMd';
import OutputListenUpDoc from './ConsoleContents/OutputListenUpDoc';
import OutputDemosListenSwift from './ConsoleContents/OutputDemosListenSwift';
import OutputAWOLDocMd from './ConsoleContents/OutputAWOLDocMd';
import OutputDemosAWOLSwift from './ConsoleContents/OutputDemosAWOLSwift';
import OutputDataLawyermd from './ConsoleContents/OutputDataLawyermd';
import OutputDataLawyerDemo from './ConsoleContents/OutputDataLawyerDemo';
import OutputParsetagramDoc from './ConsoleContents/OutputParsetagramDoc';
import OutputParsetagramDemo from './ConsoleContents/OutputParsetagramDemos';
import OutputTwootiedoc from './ConsoleContents/OutputTwootieDocmd';
import OutputTwootieDemo from './ConsoleContents/OutputTwootieDemo';
import OutputFlixMoviesDoc from './ConsoleContents/OutputFlixMoviesDocmd';
import OutputFlixMoviesDemo from './ConsoleContents/OutputFlixMoviesDemo';

function Console({ runClickCounter, selectedFile }) {
  const [compilationStatus, setCompilationStatus] = useState('Compiling...');
  const [isCompilationFinished, setIsCompilationFinished] = useState(false);

  useEffect(() => {
    setCompilationStatus('Compiling...');
    setIsCompilationFinished(false);
  
    const timer = setTimeout(() => {
      setCompilationStatus('Compiled successfully!');
      setIsCompilationFinished(true);
    }, 500); //def 2000
  
    return () => clearTimeout(timer);
  }, [selectedFile, runClickCounter]);


  const hideconsole = () => {
    document.querySelector('.console').style.display = 'none';
  };

  const renderConsoleContent = () => {
    if (isCompilationFinished) {
      if (selectedFile && selectedFile.name === 'contact.md') {
        return <OutputContactMd />;
      } 
      else if (selectedFile && selectedFile.name === 'cv.md') {
      return <OutputCVHtml />;
      } 
      else if (selectedFile && selectedFile.name === 'WhyVSCode.js') {
        return <OutputWhyVSCode />;
      }
      else if (selectedFile && selectedFile.name === 'ListenUpDoc.md') {
        return <OutputListenUpDoc />;
      }
      else if (selectedFile && selectedFile.name === 'Demos_Listen.swift') {
        return <OutputDemosListenSwift />;
      }
      else if (selectedFile && selectedFile.name === 'AWOLDoc.md') {
        return <OutputAWOLDocMd />;
      }
      else if (selectedFile && selectedFile.name === 'Demos_AWOL.swift') {
        return <OutputDemosAWOLSwift />;
      }
      else if (selectedFile && selectedFile.name === 'DataLawyer.md') {
        return <OutputDataLawyermd />;
      }
      else if (selectedFile && selectedFile.name === 'Demos_Lawyer.css') {
        return <OutputDataLawyerDemo />;
      }
      else if (selectedFile && selectedFile.name === 'ParsetagramDoc.md') {
        return <OutputParsetagramDoc />;
      }
      else if (selectedFile && selectedFile.name === 'Demos_Parse.swift') {
        return <OutputParsetagramDemo />;
      }
      else if (selectedFile && selectedFile.name === 'TwootieDoc.md') {
        return <OutputTwootiedoc />;
      }
      else if (selectedFile && selectedFile.name === 'Demos_Twootie.swift') {
        return <OutputTwootieDemo />;
      }
      else if (selectedFile && selectedFile.name === 'FlixMoviesDoc.md') {
        return <OutputFlixMoviesDoc />;
      }
      else if (selectedFile && selectedFile.name === 'Demos_Flix.swift') {
        return <OutputFlixMoviesDemo />;
      }







      else {
        return <LandingPage />;
      }
    }
  };

  return (
    <div className="console">
      <div className="console-header">
        <div className="console-tabs">
          <div className="tab">Problems</div>
          <div className="tab">Output</div>
          <div className="tab">Debug Console</div>
          <div className="tab active">Terminal</div>
          <div className="tab">Ports</div>
        </div>

        <div className="console-actions">
          <div className="console-action" onClick={hideconsole}>
            <i className="fa fa-times"></i>
          </div>
        </div>
      </div>

      <div className="console-content-wrapper">
      <div className="console-content">
        <span className={isCompilationFinished ? 'green' : ''}>
          {compilationStatus}
        </span>
        <br />
        <br />
        {renderConsoleContent()}
        <br />
        <br />
      </div>
    </div>
  </div>
);
}
export default Console;

