import React from 'react';
import './Navbar.css';

function Navbar({ handleRunClick }) {
  const showConsole = () => {
    document.querySelector('.console').style.display = 'block';
    if (handleRunClick) {
      handleRunClick();
    }
  };

  return (
    <div className="navbar">
      <div className="navbar-tabs">
        <div className="tab-container">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
            <path d="M6 4h12v1H6zm0 3h8v1H6zm0 3h10v1H6zm0 3h8v1H6z" fill="currentColor"/>
          </svg>
          <span className="tab-name">Anthony Narvaez 1.0.1</span>
          <span className="close-tab">x</span>
        </div>
      </div>

      <div className="navbar-actions">
        <span className="run-icon" onClick={showConsole }>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
            <path d="M8 5v14l11-7z" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </span>
        <span className="stop-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
            <rect x="5" y="6" width="14" height="12" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 6v12" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </span>
        <span className="more-options">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
            <circle cx="5" cy="12" r="1" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="12" cy="12" r="1" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="19" cy="12" r="1" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </span>
      </div>
    </div>
  );
}

export default Navbar;
