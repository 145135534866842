import React, { useState } from 'react';
import './Explorer.css';
import { AiOutlineFile } from 'react-icons/ai';
import { DiJavascript1, DiSwift  } from 'react-icons/di';
import { HiHashtag } from 'react-icons/hi';
import { TbBrandGithub } from 'react-icons/tb';
import { RiLinkedinBoxFill } from 'react-icons/ri';
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import { GoBrowser } from 'react-icons/go';

function Explorer({ selectedTab, explorerWidth, onFileSelect }) {
  const [openFolders, setOpenFolders] = useState([]);

  const toggleFolder = (folderName) => {
    setOpenFolders((prevOpenFolders) => {
      if (prevOpenFolders.includes(folderName)) {
        return prevOpenFolders.filter((name) => name !== folderName);
      } else {
        return [...prevOpenFolders, folderName];
      }
    });
  };

  const handleItemClick = (file) => {
    if (file.type === 'folder') {
      toggleFolder(file.name);
    } else if (file.type === 'li') {
      window.open('https://www.linkedin.com/in/anthonynarvaez/', '_blank');
    } else if (file.type === 'gh') {
      window.open('https://github.com/EazyCreator', '_blank');
    } else if (file.type === 'cv') {
      window.open('https://docs.google.com/document/d/1RyMAonpmuP28oqx6D-c7lYQ34mCIj4-UZ93lcxR757M/edit?usp=sharing', '_blank');
    } else {
      onFileSelect(file);
    }
  };

  const renderFiles = (files, level = 0) => {
    return files.map((file, index) => (
      <React.Fragment key={index}>
        <div
          className="explorer-item"
          style={{ paddingLeft: 16 * level }}
          onClick={() => {
            handleItemClick(file);
          }}
        >
          {file.type === 'file' && <AiOutlineFile />}
          {file.type === 'js' && <DiJavascript1 color="yellow" />}
          {file.type === 'css' && <HiHashtag color="lightblue" />}
          {file.type === 'li' && <RiLinkedinBoxFill color="lightblue" />}
          {file.type === 'gh' && <TbBrandGithub color="Lightgrey" />}
          {file.type === 'swift' && <DiSwift color="orange" />}
          {file.type === 'cv' && <GoBrowser color="orange" />}
          {file.type === 'html' && <GoBrowser color="orange" />}

          {file.type === 'folder' && (
            <>{openFolders.includes(file.name) ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}</>
          )}
          <span>{file.name}</span>
        </div>
        {file.type === 'folder' && openFolders.includes(file.name) && renderFiles(file.children, level + 1)}
      </React.Fragment>
    ));
  };


  const files = {
    home: [
      { name: 'index.html', type: 'file' },
      { name: 'WhyVSCode.js', type: 'js' },
    ],
    projects: [
      {
        name: 'ListenUp',
        type: 'folder',
        children: [
          { name: 'ListenUpDoc.md', type: 'file' },
          { name: 'Demos_Listen.swift', type: 'swift' },
        ],
      },
      { name: 'A.W.O.L.', type: 'folder', children: [
        { name: 'AWOLDoc.md', type: 'file' },
        { name: 'Demos_AWOL.swift', type: 'swift' },
      ] },
      { name: 'Data_Lawyer', type: 'folder', children: [
        { name: 'DataLawyer.md', type: 'file' },
        { name: 'Demos_Lawyer.css', type: 'css' },
      ] },
      { name: 'Parsetagram', type: 'folder', children: [
        { name: 'ParsetagramDoc.md', type: 'file' },
        { name: 'Demos_Parse.swift', type: 'swift' },
      ] },
      { name: 'Twootie', type: 'folder', children: [
        { name: 'TwootieDoc.md', type: 'file' },
        { name: 'Demos_Twootie.swift', type: 'swift' },
      ] },
      { name: 'Flix Movies', type: 'folder', children: [
        { name: 'FlixMoviesDoc.md', type: 'file' },
        { name: 'Demos_Flix.swift', type: 'swift' },
      ] },
      // { name: 'LaunchApps GUI', type: 'folder', children: [
      //   { name: 'LaunchGUIDoc.md', type: 'file' },
      //   { name: 'Demos_LaunchGUI.css', type: 'css' },
      // ] },
      // { name: 'TurtleVHare', type: 'folder', children: [
      //   { name: 'TurtleVHare.md', type: 'file' },
      //   { name: 'Demos_TvsH.css', type: 'css' },
      // ] },
   


    ],
    cv: [
      { name: 'cv.md', type: 'file' },
      { name: 'PrintableCV.html', type: 'cv' },
    ],
    contact: [
      { name: 'contact.md', type: 'file' },
      // { name: 'contact.css', type: 'file' },
    ],
    Social_Media: [
      { name: 'Linkedin.html', type: 'li' },
      { name: 'Github.html', type: 'gh' },
    ],
  };

  return (
    selectedTab && (
      <div className="explorer" style={{ width: explorerWidth }}>
        <div className="explorer-title">
          <h2>{selectedTab.charAt(0).toUpperCase() + selectedTab.slice(1)}</h2>
        </div>
        <div className="explorer-content">{renderFiles(files[selectedTab])}</div>
      </div>
    )
  );
}

export default Explorer;
