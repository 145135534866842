import React, { useState } from 'react';
import emailjs from 'emailjs-com';

// Replace with your public API key
const PUBLIC_API_KEY = 'cy9-7BsVYk1vgbN4F';

emailjs.init(PUBLIC_API_KEY);

function ContactHtml() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send('service_dqj91sr', 'template_q3r7cwy', {
        from_name: name,
        from_email: email,
        subject: subject,
        message: message,
        reply_to: email,
      })
      .then(
        (result) => {
          console.log(result.text);
          setSent(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const inputStyle = {
    margin: '10px',
    width: '300px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  };

  const buttonStyle = {
    backgroundColor: '#61dafb',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '10px',
    cursor: 'pointer',
    borderRadius: '5px',
  };

  return (
    
    <div>
            <h1>Contact Me<span className='cursor'>|</span></h1><br />

      {sent ? (
        <p>Your message has been sent!</p>
      ) : (
        <form onSubmit={handleSubmit} style={formStyle}>
          <label htmlFor="name">Your name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            style={inputStyle}
          />
          <label htmlFor="email">Your email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={inputStyle}
          />
          <label htmlFor="subject">Subject:</label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
            style={inputStyle}
          />
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            style={{ ...inputStyle, height: '100px' }}
          />
          <br />
          <button type="submit" style={buttonStyle}>
            Send Email
          </button>
        </form>
      )}
    </div>
  );
}

export default ContactHtml;
