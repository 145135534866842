import React from 'react';
import './Sidebar.css';
import { VscFiles, VscListFlat } from 'react-icons/vsc';
import {IoShareSocialOutline} from 'react-icons/io5';

const Sidebar = ({ selectedTab, handleTabClick }) => {
  return (
    <div className="sidebar">
      {/* <img className="placeholder" src={`${process.env.PUBLIC_URL}/logo192.png`} alt="Placeholder" /> */}
      <nav>
        <ul>
          <li>
            <a
              href="#home"
              className={selectedTab === 'home' ? 'active' : ''}
              onClick={() => handleTabClick('home')}
            >
              <i className="fa fa-home"></i>
            </a>
          </li>
          <li>
            <a
              href="#projects"
              className={selectedTab === 'projects' ? 'active' : ''}
              onClick={() => handleTabClick('projects')}
            >
              <VscFiles />
            </a>
          </li>
          <li>
            <a
              href="#cv"
              className={selectedTab === 'cv' ? 'active' : ''}
              onClick={() => handleTabClick('cv')}
            >
              <VscListFlat />
            </a>
          </li>
          <li>
            <a
              href="#contact"
              className={selectedTab === 'contact' ? 'active' : ''}
              onClick={() => handleTabClick('contact')}
            >
              <i className="fa fa-envelope"></i>
            </a>
          </li>

          <li>
            <a
              href="#social_media"
              className={selectedTab === 'Social_Media' ? 'active' : ''}
              onClick={() => handleTabClick('Social_Media')}
            >
              <IoShareSocialOutline />
            </a>
          </li>

          

          

        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
