import React from 'react';

function OutputFlixMoviesDemo() {
  return (
    <div>
    <p>I still use Flix Movies to see if there are any new releases. It was one of my favorite projects when I began learning how to be an iOS Developer.</p>
    </div>
  );
}

export default OutputFlixMoviesDemo;
