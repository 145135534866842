import React from 'react';

function DataLawyerReadme() {
  return (
    <div className="why-vscode-container"> 
      <h1>Data Lawyer<span className='cursor'>|</span></h1>

      <h2>What is Data Lawyer?</h2>
      <p>Data Lawyer is a revolutionary web application designed to simplify the task of understanding privacy policies. It employs the cutting-edge capabilities of OpenAI's GPT-3 model to summarize complex privacy policies, providing users with digestible, easy-to-understand summaries. This empowers individuals to make informed decisions about their data privacy.</p>
      
      <hr />

      <h2>Languages and Technologies</h2>
      <p>Data Lawyer was developed primarily in JavaScript. I also leveraged the OpenAI API, a powerful tool that allowed us to harness the capabilities of GPT-3, one of the most advanced language understanding AI models available today.</p>
      
      <hr />

      <h2>The Future of Data Lawyer</h2>
      <p>The potential for Data Lawyer is vast. As AI technology continues to advance, I plan to incorporate newer models, such as GPT-4, into Data Lawyer. I conducted preliminary tests with GPT-4 and found promising results. However, due to considerations regarding API pricing and token size limitations for each pasted privacy policy, the integration of GPT-4 will be part of a future version of Data Lawyer.</p>
      
      <hr />

      <h2>The Most Rewarding Part of Building Data Lawyer</h2>
      <p>One of the most rewarding aspects of developing Data Lawyer was the ability to see AI in action, producing tangible output live on the web page. It served as a stark reminder of the remarkable advancements in AI technology in recent years. Building a tool that could potentially make a significant difference in how individuals understand and manage their data privacy felt truly fulfilling.</p>
    </div>
  );
}

export default DataLawyerReadme;
