import React from 'react';

function FlixMoviesDemo() {
  const imageStyle = {
    width: '300px',
    height: 'auto',
  };

  const sectionStyle = {
    marginBottom: '20px',
  };

  return (
    <div className="why-vscode-container"> 
      <h1>Flix Movies - App Demo<span className='cursor'>|</span></h1>
      <div style={sectionStyle}>
        <p>Flix Movies is a powerful and intuitive movie database app built using Swift and SwiftUI. It leverages The Movie Database API to provide users with a rich array of cinematic offerings.</p>
        
        <h2>Feature Overview</h2>
        <p>The GIF demonstration below showcases the core functionality of Flix Movies. From the moment of launch, users are greeted with a list of popular movies currently showing in theaters. Each movie cell can be tapped to reveal more details about the film, allowing users to dive deeper into their favorite movies.</p>
        
        <p>One of the standout features of Flix Movies is the tab bar button that enables users to view a grid layout of movie posters using a CollectionView. This presents a visual feast of cinematic offerings, allowing users to browse and discover new films at their leisure.</p>
        
        
        <p>Experience the comprehensive features of Flix Movies through the GIF demonstration below.</p>
        
        <img src="/FlixMoviesDemo.gif" alt="Flix Movies Full Feature Demo" className="FlixMovies-Demo" style={imageStyle} />
      </div>
    </div>
  );
}

export default FlixMoviesDemo;
