import React from 'react';

function OutputParsetagramDoc() {
  return (
    <div>
      <p>Parsetagram was built during my time at Codepath. I learned a lot about UIKit, but since have moved on to SwiftUI.</p>
    </div>
  );
}

export default OutputParsetagramDoc;
