import React from 'react';

function OutputDemosListenSwift() {
  return (
    <div>
      <p>One of my favorite ListenUp features other than the music list, is the subtle background change when new music is found.</p>
    </div>
  );
}

export default OutputDemosListenSwift;
