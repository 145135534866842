import React from 'react';
import './MainContent.css';
import Navbar from './Navbar';
import Editor from './Editor';
import Console from './Console';
import Footer from './Footer';

function MainContent({ position, selectedFile, lastRunFile, runClickCounter, handleRunClick }) {
  return (
    <div className="main">
    <div className="content">
      <Navbar handleRunClick={handleRunClick} />
      <Editor selectedFile={selectedFile} />
      <Console selectedFile={lastRunFile} runClickCounter={runClickCounter} />
    </div>
    <Footer position={position} />
  </div>
);
}

export default MainContent;
