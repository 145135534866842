import React from 'react';

function StyleCss() {
  const imageStyle = {
    width: '300px',
    height: 'auto',
  };

  const sectionStyle = {
    marginBottom: '20px',
  };

  return (
    <div className="why-vscode-container"> 
      <h1>Twootie - App Demo<span className='cursor'>|</span></h1>
      <div style={sectionStyle}>
        <p>Twootie is a straightforward and effective Twitter client, developed with a focus on core functionalities. This project was built using UIKit in Xcode, Swift, and the Twitter API.</p>
        
        <h2>Feature Overview</h2>
        <p>The GIF demonstration showcases the core functionality of Twootie. Starting with the login and logout process, it highlights the user-friendly and persistent session management. No matter how many times the app restarts, the user remains logged in, providing a seamless experience.</p>
        
        <p>One of the main features is the ability to view tweets in a simple and effective layout. The demo showcases how each tweet is presented with the user's profile picture, username, and the tweet text.</p>
        
        <p>A particularly engaging feature of Twootie is the ability to refresh the feed with a simple pull-down gesture, allowing for real-time updates. Additionally, the app supports infinite scrolling, which lets users delve deeper into their feed without interruption.</p>
        
        <p>Despite its simplicity, Twootie offers an efficient and user-friendly Twitter experience, making it a valuable addition to any portfolio. Have a look at the GIF demonstration for a complete overview of the app's features.</p>
        
        <img src="/TwootieDemo.gif" alt="Twootie Full Feature Demo" className="Twootie-Demo" style={imageStyle} />
      </div>
    </div>
  );
}

export default StyleCss;
