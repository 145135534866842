import React from 'react';

function ListenUpReadme() {
  return (
    <div className="why-vscode-container"> 
      <h1>A.W.O.L<span className='cursor'>|</span></h1>
      <p>
        <br />
        <h2>What is A.W.O.L.?</h2>
        <br />
        <p>A.W.O.L. is a Wake on LAN application with a twist. It allows you to wake multiple devices at once, with a singular button.

        </p>
        <p> You can download A.W.O.L. <a href="https://apps.apple.com/us/app/a-w-o-l/id6444170691">here</a>.</p>
        <br/>
        <hr />
        <br />
        <p>
          <h2>Languages</h2>
          <br />
          <p>A.W.O.L. was built in Swift, utilizing UIKit. Network requests are made on your local network to wake any listed computers.</p>
        <br/>
          
          <br/>
        <hr />
        <br />
          <h2>Future of A.W.O.L.</h2>
          <br />
          <p>A feature I'd like to see implemented into A.W.O.L. is the ability to create shortcuts with Siri and a widget. </p>
            <br/>
        <hr />
        <br />
          <h2>What was the most rewarding part of building A.W.O.L?</h2>
          <br />
          <p>The most rewarding part of building A.W.O.L. was definitely seeing all computers on my network all turn on at the same time. Since I use multiple computers in my workstation, it does help a lot.  </p>
            </p>
            <br/>

      </p>
      

    </div>
  );





}

export default ListenUpReadme;
