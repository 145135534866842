import React from 'react';

function StyleCss() {
  const imageStyle = {
    width: '300px',
    height: 'auto',
  };

  return (
    <div className="why-vscode-container"> 
      <h1>A.W.O.L. Demo's<span className='cursor'>|</span></h1>
      <p>
        <br />
        <h2>Wake on LAN Demo</h2>
        <br />
        <p>
            <strong>A.W.O.L.</strong> is a Wake on LAN application with a twist. It allows you to wake multiple devices at once, with a singular button
            press. With this, you're able to wake your entire home network with a single button press.

        </p>
        <img src="/AWOL_Button_Demo.gif" alt="ListenUp Demo" className="ListenUp-Demo" style={imageStyle} />
        <br/>
        <hr />
        <br />
        <p>
          <h2>Device List Demo</h2>
          <br />
          <p>The device list is available to all A.W.O.L. users. There, you're able to manage and add new devices manually.</p>
          <img src="AWOL_DeviceList_Demo.gif" alt="ListenUp List Gif Demo" style={imageStyle}></img></p>
        <br/>

        <br />

      </p>
    </div>
  );
}

export default StyleCss;
