import React from 'react';

function ListenUpReadme() {
  return (
    <div className="why-vscode-container"> 
      <h1>Twootie<span className='cursor'>|</span></h1>
      <p>
        <br />
        <h2>What is Twootie?</h2>
        <br />
        <p>Twootie is a social media app that utilizes the Twitter API. Users are able to login to Twitter, see their timeline, and post tweets. All built in the likeness of Twitter.
        </p>
        <br/>
        <hr />
        <br />
        <p>
          <h2>Languages</h2>
          <br />
          <p>Twootie was built in Swift, utilizing UIKit. The Twitter API is called to have users sign into their twitter accounts, and fetch data.</p>
        <br/>
          
          <br/>
        <hr />
        <br />
          <h2>Future of Twootie</h2>
          <br />
          <p>Twootie is a project that made me comfortable working with the Twitter API. To learn features such as infinite scrolling, I thought Twitter would be the best social media to work with. No further updates will be made for this project. </p>
            <br/>
        <hr />
        <br />
          <h2>What was the most rewarding part of building Twootie?</h2>
          <br />
          <p>The most rewarding part of building Twootie was seeing infinite scrolling work seamlessly in real time. Tweets were fetched with pull to reload and scrolling seamlessly.  </p>
            </p>
            <br/>

      </p>
      

    </div>
  );





}

export default ListenUpReadme;
