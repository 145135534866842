import React from 'react';

function AWOLReadme() {
  return (
    <div className="why-vscode-container"> 
      <h1>A.W.O.L<span className='cursor'>|</span></h1>
        <br />
        <h2>Introduction to A.W.O.L.</h2>
        <br />
        <p>A.W.O.L. is not your ordinary Wake on LAN application. It's designed to provide an efficient way to wake multiple devices simultaneously with just a single button press. If you often work with multiple devices on your network, A.W.O.L. can be a game-changer.</p>
        <p>Experience the power of A.W.O.L., download it <a href="https://apps.apple.com/us/app/a-w-o-l/id6444170691" target="_blank" rel="noopener noreferrer">here</a>.</p>
        <br/>
        <hr />
        <br />
        <h2>The Tech behind A.W.O.L.</h2>
        <br />
        <p>A.W.O.L. is built in Swift, leveraging UIKit for its user interface. The application performs network requests within your local network to wake any registered computers. The process is seamless and quick, saving you time and effort.</p>
        <br/>
        <hr />
        <br />
        <h2>The Future of A.W.O.L.</h2>
        <br />
        <p>Looking ahead, I'm exploring the possibility of integrating Siri shortcuts and a widget into A.W.O.L. This would offer even more convenience and functionality for users, allowing them to wake their devices without even opening the app.</p>
        <br/>
        <hr />
        <br />
        <h2>The Most Rewarding Part of Building A.W.O.L.</h2>
        <br />
        <p>The most rewarding part of building A.W.O.L. was the moment when I first saw all computers on my network turn on simultaneously. It was amazing, especially knowing that I'm using multiple computers for my work. It's a small yet significant convenience that has made my workflow more efficient.</p>
        <br/>
    </div>
  );
}

export default AWOLReadme;
