import React from 'react';

function OutputListenUpDoc() {
  return (
    <div>
      <p>ListenUp is one of my favorite applications to date. I use it whenever I know there is going to be music playing, just so I can make a playlist for wherever I go. </p>
    </div>
  );
}

export default OutputListenUpDoc;
