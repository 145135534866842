import React from 'react';
import './CVStyles.css'; // Import the CSS file
import useTypingEffect from '../useTypingEffect';

function CVHtml() {
  const headerName = useTypingEffect("Anthony Narvaez", 10);
  const headerLocation = useTypingEffect("Brooklyn, NY • anarvaezrs@gmail.com", 10);
  const headerLinkedIn = useTypingEffect("linkedin.com/in/anthonynarvaez/", 10);
  const educationSectionTitle = useTypingEffect("EDUCATION", 10);
  const educationSectionContent = useTypingEffect("Bachelor Of Science in Computer Science - John Jay College of Criminal Justice", 10);
  const employmentSectionTitle = useTypingEffect("EMPLOYMENT", 10);
  const bergenTitle = useTypingEffect("Bergen Basin Realty - Data Engineer", 10);
  const meAndGTitle = useTypingEffect("Me & G Creations - Software Engineer", 10);
  const latestProjectsTitle = useTypingEffect("LATEST PROJECTS", 10);
  const listenUpTitle = useTypingEffect("ListenUp - Music Identifier", 10);
  const dataLawyerTitle = useTypingEffect("Data Lawyer", 10);
  const AWOLTitle = useTypingEffect("A.W.O.L.", 10);

  const bergenDetails = [
    "Enhanced customer retention by 30% in 6 months by organizing and filtering new and dated customer data.",
    "Improved data research by utilizing and maintaining the M.L.S. database for new and existing inventory in 30 days to improve the overall experience of the brokerage.",
    "Initiated automated letter writing by utilizing customer data to decrease the time to write letters by more than by 500% in 2 weeks resulting in a faster idea to marketing campaign.",
  ].map(useTypingEffect);

  const meAndGDetails = [
    "Enhanced time complexity in internal C++ functions by 30 milliseconds in 1 week by refactoring legacy source code.",
    "Reduced product delivery time by 20% in 2 weeks by utilizing agile methodologies.",
    "Established object-oriented design principles into more than 50 projects.",
  ].map(useTypingEffect);

  const listenUpDetails = [
    "Formulated a SwiftUI based home screen utilizing design principles.",
    "Boosted revenue by 800% by incorporating a paywall.",
    "Organized music captured into lists by date for ListenUp+ subscribers.",
  ].map(useTypingEffect);

  const dataLawyerDetails = [
    "Contributed to an AI driven solution to data privacy, increasing processing speeds by 150% in 3 weeks to increase user engagement.",
    "Fabricated a user-friendly interface utilizing React.js to give users ease of access.",
    "Accomplished product delivery of DataLawyer, giving users access resulting in a completed project.",
  ].map(useTypingEffect);

  const AWOLDetails = [
    "Pioneered programming features in Swift for users to wake computers on their network from 1 at a time to up to 100 at a time in 4 weeks resulting in increased user interest. ",
    "Achieved product delivery in 5 weeks by utilizing an Agile methodology ",
  ].map(useTypingEffect);

  return (
    <div className="cv-container">
      <div className="cv-header">
        <h1>{headerName}</h1>
        <p>{headerLocation}</p>
        <p>{headerLinkedIn}</p>
      </div>

      <div className="cv-section">
        <div className="cv-section-title">{educationSectionTitle}</div>
        <div className="cv-section-content">{educationSectionContent}</div>
      </div>

      <div className="cv-section">
        <div className="cv-section-title">{employmentSectionTitle}</div>
        <div className="cv-projects-container">
          <div className="cv-project">
            <div className="cv-project-title">{bergenTitle}</div>
            <ul className="cv-project-details">
              {bergenDetails.map((detail, index) => (
            <li key={index}>{detail}</li>
            ))}
          </ul>
        </div>
  
        <div className="cv-project">
          <div className="cv-project-title">{meAndGTitle}</div>
          <ul className="cv-project-details">
            {meAndGDetails.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  
    <div className="cv-section">
      <div className="cv-section-title">{latestProjectsTitle}</div>
      <div className="cv-projects-container">
        <div className="cv-project">
          <div className="cv-project-title">{listenUpTitle}</div>
          <ul className="cv-project-details">
            {listenUpDetails.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
        </div>
  
        <div className="cv-project">
          <div className="cv-project-title">{dataLawyerTitle}</div>
          <ul className="cv-project-details">
            {dataLawyerDetails.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
        </div>
  
        <div className="cv-project">
          <div className="cv-project-title">{AWOLTitle}</div>
          <ul className="cv-project-details">
            {AWOLDetails.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
);
}

export default CVHtml;  