import React, { useState, useEffect } from 'react';
import './App.css';
import Sidebar from './Sidebar';
import Explorer from './Explorer';
import MainContent from './MainContent';
import 'font-awesome/css/font-awesome.min.css';

function App() {
  // State to track mouse position
  const [position, setPosition] = useState({ line: 1, column: 1 });
  // State to track which tab is selected
  const [selectedTab, setSelectedTab] = useState('');
  // State to track width of explorer panel
  const explorerWidth = 200;

  const [selectedFile, setSelectedFile] = useState(null);
  

  // Handler for mouse move event
  const handleMouseMove = (e) => {
    setPosition({ line: e.clientY, column: e.clientX });
  };

  
  // Handler for tab click event
  const handleTabClick = (tab) => {
    setSelectedTab((prevSelectedTab) => (prevSelectedTab === tab ? '' : tab));
  };

  // Effect to set CSS variable for explorer panel width
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--explorer-width',
      explorerWidth + 'px'
    );
  }, [explorerWidth]);

  
const [runClickCounter, setRunClickCounter] = useState(0);
const [lastRunFile, setLastRunFile] = useState(null);

const handleRunClick = () => {
  
  setRunClickCounter((prevCounter) => prevCounter + 1);
  setLastRunFile(selectedFile);
  
};


  return (
    <div className="vscode" onMouseMove={handleMouseMove}>
      <Sidebar selectedTab={selectedTab} handleTabClick={handleTabClick} />
      <Explorer
        selectedTab={selectedTab}
        explorerWidth={explorerWidth}
        onFileSelect={setSelectedFile}
      />
 <MainContent
  position={position}
  selectedFile={selectedFile}
  lastRunFile={lastRunFile}
  runClickCounter={runClickCounter}
  handleRunClick={handleRunClick}
/>

    </div>
  );
}

export default App;
